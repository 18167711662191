import { css } from '@emotion/react'
import { LinkBase } from '../../atoms/Link/LinkBase'

interface Props {
  titleEnglish: string
  imagePath: string
  title: string
  content: string
  href: string
}

/**
 * カード、トップページの事業案内で使用
 * @param {string} imagePath
 * @param {string} title
 * @param {string} titleEnglish
 * @param {string} content
 * @param {string} href
 */
/** */
export const CardServiceTop = ({
  titleEnglish,
  imagePath = 'common/default.png',
  title,
  content,
  href,
}: Props) => {
  return (
    <li
      css={css`
        transition-duration: 0.3s;
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      `}
      className="relative list-none bg-white"
    >
      <LinkBase href={href}>
        <div className="overflow-hidden relative w-full h-[142px] tab:h-[254px]">
          <img
            src={imagePath || '/common/img/default.png'}
            alt={title}
            className="absolute top-0 left-0 w-full h-full object-cover duration-default"
          />
        </div>
        <div className="pc:p-8 px-5 py-6">
          <h3 className="font-bold text-lg pc:text-xl leading-tight">
            {title}
          </h3>
          <p className="text-primary mt-1 leading-tight font-medium font-Rajdhani text-xs">
            {titleEnglish}
          </p>
          <p className="text-md leading-normal mt-3">{content}</p>
        </div>
      </LinkBase>
    </li>
  )
}
