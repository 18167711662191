import { css } from '@emotion/react'
import { LinkBase } from '../../atoms/Link/LinkBase'

interface Props {
  titleEnglish: string
  imagePath: string
  title: string
  href: string
}

/**
 * カード、トップページのIRで使用
 * @param {string} imagePath
 * @param {string} title
 * @param {string} titleEnglish
 * @param {string} href
 */
/** */
export const CardTopIR = ({
  titleEnglish,
  imagePath = '/common/img/default.png',
  title,
  href,
}: Props) => {
  return (
    <li
      css={css`
        img {
          transition: 0.3s;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      `}
    >
      <LinkBase href={href}>
        <div className="hidden pc:block overflow-hidden">
          <img
            src={imagePath || '/common/img/default.png'}
            alt=""
            width={368}
            height={300}
          />
        </div>
        <div className="pc:hidden overflow-hidden">
          <img
            src={imagePath || '/common/img/default.png'}
            alt=""
            width={670}
            height={360}
          />
        </div>
        <p className="mt-4 pc:mt-6 leading-tight font-bold text-lg pc:text-xl">
          {title}
        </p>
        <span className="pt-1 inline-block text-primary leading-tight font-medium font-Rajdhani text-xs">
          {titleEnglish}
        </span>
      </LinkBase>
    </li>
  )
}
