import React, { ReactNode } from 'react'
import { MvTop } from '../Mv/MvTop'
import { LayoutRoot } from './LayoutRoot'

interface Props {
  children: ReactNode
}

/**
 * トップページのレイアウト
 * @param {ReactNode} children
 */
/** */
export const LayoutTop = ({ children }: Props) => {
  return (
    <LayoutRoot>
      <MvTop />
      {children}
    </LayoutRoot>
  )
}
