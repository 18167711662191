import { css } from '@emotion/react'
import { LinkBase } from '../../atoms/Link/LinkBase'
import { SvgArrowIconSizeM } from '../../atoms/Svg/SvgArrowIconSizeM'
import { hoverArrowIconWhite } from '../../styles/animation'

interface Props {
  heading: string
  headingEnglish: string
  imagePath: string
  href: string
  className?: string
  isLarge?: boolean
}

/**
 * カード、トップに使用、正方形
 * isLargeなら長方形
 * @param {string} heading
 * @param {string} headingEnglish
 * @param {string} imagePath
 * @param {string} href
 * @param {string} className
 * @param {boolean} isLarge
 */
/** */
export const CardPrimary = ({
  heading,
  headingEnglish,
  imagePath,
  href,
  className,
  isLarge = false,
}: Props) => {
  return (
    <li
      css={css`
        width: 100%;
        @media screen and (min-width: 520px) {
          width: ${isLarge ? '100%' : '100%'};
        }
        @media screen and (min-width: 1200px) {
          width: ${isLarge ? '583px' : '276px'};
        }
        transition-duration: 0.3s;
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
        ${hoverArrowIconWhite}
      `}
      className={`list-none duration-default overflow-hidden ${
        className ? className : ''
      }`}
    >
      <LinkBase href={href}>
        <div className="relative ">
          <div className={`relative z-10 pc:h-[276px] tab:h-[252px] h-[160px]`}>
            <p
              className="text-white pt-3 pl-3 pc:pt-5 pc:pl-5 font-bold leading-tight text-md pc:text-lg"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
            <p
              className="mt-1 pc:mt-2 pl-3 pc:pl-5 text-white font-Rajdhani font-medium leading-tight text-xs pc:text-sm"
              dangerouslySetInnerHTML={{ __html: headingEnglish }}
            />
            <SvgArrowIconSizeM />
          </div>
          <img
            src={imagePath}
            alt=""
            css={css`
              position: absolute;
              transition-duration: 0.3s;
              z-index: 0;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            `}
          />
        </div>
      </LinkBase>
    </li>
  )
}
