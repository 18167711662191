import React from 'react'
import { HeadingFactory } from './HeadingFactory'

interface Props {
  label: string
  EnglishLabel: string
  level?: '1' | '2' | '3' | '4' | '5' | '6'
}

/**
 * h1での使用を想定。
 * カテゴリートップページでの大見出し、英字が赤で大きい
 * @param {string} label テキストです。
 * @param {string} EnglishLabel 英字のテキストです。
 * @param {'1' | '2' | '3' | '4' | '5' | '6'} level h1,h2,h3...の番号部分。
 */
/** */
export const HeadingPrimary = ({ EnglishLabel, label, level = '1' }: Props) => {
  return (
    <>
      <div
        className={`text-[56px] font-Rajdhani font-medium text-primary pc:text-[80px]`}
      >
        {EnglishLabel}
      </div>
      <HeadingFactory
        level={level}
        className={`text-md mt-1 font-bold text-text10`}
      >
        {label}
      </HeadingFactory>
    </>
  )
}
