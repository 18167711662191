// import Swiper core and required modules
import { A11y, EffectFade, Autoplay } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css/bundle'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { LinkBase } from '../../atoms/Link/LinkBase'
import { colorList } from '../../styles/color'
import { ENGLISH_TITLE } from '../../data/English'

const images = [
  { pc: '/top/img/mv1pc.jpg', sp: '/top/img/mv1sp.jpg' },
  { pc: '/top/img/mv2pc.jpg', sp: '/top/img/mv2sp.jpg' },
  { pc: '/top/img/mv3pc.jpg', sp: '/top/img/mv3sp.jpg' },
  { pc: '/top/img/mv4pc.jpg', sp: '/top/img/mv4sp.jpg' },
  { pc: '/top/img/mv5pc.jpg', sp: '/top/img/mv5sp.jpg' },
]

const styleBullet = css`
  .swiper-pagination {
    text-align: left;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1200px;
    @media screen and (min-width: 1200px) {
      bottom: 80px;
    }
    @media screen and (max-width: 1199px) {
      padding-left: 20px;
      bottom: 210px;
    }
    .svg {
      transform: rotate(-90deg);
    }
    .circle {
      stroke-width: 1px;
      fill: transparent;
      &.active {
        stroke: #fff;
        stroke-dasharray: 57px;
        stroke-dashoffset: 0;
        animation: circleAnim 6s linear;
      }
    }
  }

  @keyframes circleAnim {
    from {
      stroke-dashoffset: 57px;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`

const services = [
  {
    title: 'ヒューマンリソース',
    en: ENGLISH_TITLE.HumanResource,
    href: '/service/hr',
  },
  {
    title: 'アウトソーシング',
    en: ENGLISH_TITLE.Outsourcing,
    href: '/service/outsourcing',
  },
  {
    title: 'システムソリューション',
    en: ENGLISH_TITLE.SystemSolution,
    href: '/service/systemsolution',
  },
  { title: 'M&A・投資', en: ENGLISH_TITLE.MandA, href: '/service/manda' },
  {
    title: 'フィナンシャル',
    en: ENGLISH_TITLE.Financial,
    href: '/service/financial',
  },
]

export const MvTop = () => {
  const [swiper, setSwiper] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [show, setShow] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 300)
  }, [])

  return (
    <div className={`pc:mt-[-74px] relative`}>
      <div
        className={`w-screen absolute h-screen duration-default ${
          show ? 'opacity-0 -z-10' : 'bg-white z-10'
        }`}
      ></div>
      <div
        css={styleBullet}
        className="w-full h-[calc(100vh-52px)] pc:h-screen"
      >
        {/* ------------ swiper ------------- */}
        <Swiper
          modules={[Autoplay, A11y, EffectFade]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          loop
          effect="fade"
          speed={1000}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.realIndex)
          }}
          onSwiper={(_swiper) => {
            setSwiper(_swiper)
          }}
        >
          {images.map((image, i) => (
            <SwiperSlide key={i}>
              <img
                css={css`
                  width: 100%;
                  height: 100vh;
                  object-fit: cover;
                `}
                className="pc:block hidden"
                src={image.pc}
                alt=""
              />
              <img
                css={css`
                  width: 100%;
                  height: calc(100vh - 52px - 186px); //100vh - header - リンク
                  object-fit: cover;
                `}
                className="pc:hidden"
                src={image.sp}
                alt=""
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* ------------ swiper ------------- */}
        {/* ------------ テキスト ------------- */}
        <div className="absolute w-full pl-5 pc:pl-0 bottom-[266px] pc:bottom-[140px] z-10">
          <div className="mx-auto text-left max-w-[1200px]">
            <span className="font-Rajdhani font-medium text-md pc:text-lg text-white">
              Enjoy growth
            </span>
            <h1 className="text-white mt-4 pc:mt-6 font-bold text-[56px] pc:text-[96px]">
              <span className="tracking-[0.011em]">成長</span>
              <span className="text-[32px] pc:text-[72px]">を</span>
              <br className="pc:hidden" />
              <span className="pt-1 pc:pt-0">愉しもう。</span>
            </h1>
            <p className="text-white text-md pc:text-xl pc:font-bold mt-4 pc:mt-6">
              <span className="pc:font-medium">CRG</span>
              ホールディングス株式会社
            </p>
          </div>
        </div>
        {/* ------------ テキスト ------------- */}
        {/* ------------ pagination ------------- */}
        <ul className="swiper-pagination space-x-4 absolute flex items-center">
          {[0, 1, 2, 3, 4].map((i) => (
            <li
              key={i}
              className="cursor-pointer"
              onClick={() => swiper && swiper.slideToLoop(i)}
            >
              <svg className="svg" width="20" height="20" viewBox="0 0 20 20">
                <circle
                  className={`circle ${activeIndex === i ? 'active' : ''}`}
                  cx="10"
                  cy="10"
                  r="9"
                />
                <circle fill="#fff" cx="10" cy="10" r="3" />
              </svg>
            </li>
          ))}
        </ul>
        {/* ------------ pagination ------------- */}
        {/* ------------ タブ部分 ------------- */}
        <div className="pc:absolute w-full z-10 bottom-0">
          <ul className="flex flex-wrap mx-auto max-w-[1200px] items-center">
            {services.map((item, i) => (
              <li
                key={i}
                onMouseEnter={() => swiper && swiper.slideToLoop(i)}
                className={`py-4 cursor-pointer text-center duration-default pc:w-[240px] ${
                  activeIndex === i ? 'bg-primary' : 'bg-bg10'
                } ${item.title === 'ヒューマンリソース' ? 'w-full' : 'w-1/2'}`}
              >
                <LinkBase className="relative block" href={item.href}>
                  <span>
                    <b
                      className={`text-xs pc:text-md block font-bold ${
                        activeIndex === i ? 'text-white' : 'text-text10'
                      }`}
                    >
                      {item.title}
                    </b>
                    <span
                      className={`font-Rajdhani block pt-1 text-xs ${
                        activeIndex === i ? 'text-white' : 'text-primary'
                      }`}
                    >
                      {item.en}
                    </span>
                  </span>
                  <span className="absolute right-2 pc:right-3 top-[calc(50%-3px)]">
                    <span className="hidden pc:block">
                      <svg
                        width="10"
                        height="7"
                        viewBox="0 0 10 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_2807_44959"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="10"
                          height="7"
                        >
                          <rect width="10" height="7" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_2807_44959)">
                          <path
                            d="M10 3.5L0.250001 6.53109L0.250001 0.468911L10 3.5Z"
                            fill={
                              activeIndex === i ? 'white' : colorList.primary
                            }
                          />
                        </g>
                      </svg>
                    </span>
                    <span className="pc:hidden">
                      <svg
                        width="8"
                        height="6"
                        viewBox="0 0 10 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_2807_44959"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="10"
                          height="7"
                        >
                          <rect width="10" height="7" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_2807_44959)">
                          <path
                            d="M10 3.5L0.250001 6.53109L0.250001 0.468911L10 3.5Z"
                            fill={
                              activeIndex === i ? 'white' : colorList.primary
                            }
                          />
                        </g>
                      </svg>
                    </span>
                  </span>
                </LinkBase>
              </li>
            ))}
          </ul>
        </div>
        {/* ------------ タブ部分 ------------- */}
      </div>
    </div>
  )
}
