// import { css } from '@emotion/react'
import { LinkBase } from '../../atoms/Link/LinkBase'
import { SvgArrowIcon } from '../../atoms/Svg/SvgArrowIcon'
import { hoverArrowIcon } from '../../styles/animation'

interface Props {
  href: string
  text: string
}

/**
 * TOPのIR情報で使用
 * ulでラップして使用。
 * @param {string} href リンクです。
 * @param {string} text テキストです。
 */
/** */
export const ListIRTop = ({ href, text }: Props) => {
  return (
    <li css={hoverArrowIcon} className="list-none border-y border-border10">
      <LinkBase className="relative block pl-3 pc:pl-4 py-6" href={href}>
        <div className={`font-bold text-md pc:text-lg`}>{text}</div>
        <div className="absolute right-0 top-[calc(50%-15px)]">
          <SvgArrowIcon />
        </div>
      </LinkBase>
    </li>
  )
}
